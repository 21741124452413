import React, { ElementType, ReactElement, useEffect, useRef } from "react";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { CaretDown, X } from "phosphor-react";

import { classNames } from "utils";
import { assertIsNode } from "utils/assert-is-node";

export interface DropdownProps {
    id?: string;
    label: string | ReactElement;
    options: string[];
    selectableOptions: true;
    onChangeFilter: (value: string) => void;
    applyFilter: () => void;
    onClear: () => void;
    selectedFilterValue: any;
    children: React.ReactNode;
    isApplied: boolean;
    classNamesContainer: string;
    showFooter: boolean;
    isShowing: boolean;
    setIsShowing: (value: any) => void;
    menuClassName: string;
    clearDefaultMenuClassName: boolean;
    menuPosClassName: string;
    disabled: boolean;
    disabledApply: boolean;
    buttonApplyLabel?: string;
    labelClassNames?: string;
    buttonClassName: string;
    buttonIconElement?: React.ReactNode;
    buttonLabelElement?: React.ReactNode;
    buttonContainerAs?: ElementType<any> | undefined;
    buttonClearDefaultClassNames?: boolean;
    headerTitle?: string;
    isCenter?: boolean;
    isChangePadding?: boolean;
    focusHandler?: () => void;
    "data-testid"?: string;
    footerClassName?: string;
    renderCustomFooter?: ReactElement | null;
    hideBackgroundOverlay?: boolean;
}

export default function Dropdown({
    id,
    showFooter = true,
    footerClassName = "",
    renderCustomFooter = null,
    isShowing = false,
    setIsShowing = () => {},
    disabled = false,
    buttonApplyLabel = "Apply",
    buttonIconElement = (
        <CaretDown
            size={15}
            className={`-mt-[2px] ml-1 transition-all fill-secondaryDark ${
                isShowing ? "rotate-180" : ""
            }`}
        />
    ),
    menuPosClassName = "",
    labelClassNames = "",
    clearDefaultMenuClassName = false,
    isCenter = false,
    isChangePadding = false,
    label = "",
    buttonLabelElement = (
        <div
            data-testid={label}
            className={labelClassNames}
            style={isCenter ? { width: "100%" } : {}}
        >
            {label}
        </div>
    ),
    buttonContainerAs = "button",
    focusHandler = () => {},
    buttonClearDefaultClassNames = false,
    hideBackgroundOverlay = false,
    ...props
}: Partial<DropdownProps>) {
    const dropDownMenuRef = useRef<HTMLDivElement>(null);
    const menuButtonRef = useRef<React.ElementRef<"div">>(null);

    const handleApply = () => {
        try {
            if (props.applyFilter) {
                props.applyFilter();
            }
            setIsShowing(false);
        } catch (e) {
            console.error(e);
        }
    };

    const handleClose = ({ target }: MouseEvent) => {
        assertIsNode(target);

        if (
            !dropDownMenuRef?.current?.contains(target) &&
            !menuButtonRef?.current?.contains(target) &&
            isShowing &&
            /*TODO should be refactored. Hotfix to calendar items because '.contains' failed with two Dropdown items */
            /*@ts-ignore*/
            !target.classList.contains("react-datepicker__day")
        ) {
            setIsShowing(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mouseup", handleClose); // click and mousedown event are prevented by Popovers core code

        return () => {
            document.removeEventListener("mouseup", handleClose);
        };
    }, [dropDownMenuRef, isShowing]);

    return (
        <Popover
            id={id}
            as="div"
            className={classNames(
                "md:relative inline-block text-left w-full md:w-auto",
                props.classNamesContainer?.length ? props.classNamesContainer : ""
            )}
        >
            <div ref={menuButtonRef}>
                <PopoverButton
                    as={buttonContainerAs}
                    className={classNames(
                        "text-left",
                        !buttonClearDefaultClassNames && "dropdown-button",
                        props.buttonClassName ? props.buttonClassName : "",
                        !!props.isApplied &&
                            "bg-successLight !text-success !border-success" /*TODO !text-success - remove important rule with removing an old version*/,
                        isShowing && "custom-color"
                    )}
                    // or div, or button, or any Element. TODO Rewrite types definition later, it's not blocker now
                    onClick={(event: React.FormEvent<HTMLButtonElement>) => {
                        if (buttonContainerAs === "button") {
                            event.stopPropagation();
                            event.preventDefault();
                        }
                        setIsShowing((isShowing: boolean) => !isShowing);
                        focusHandler();
                    }}
                    disabled={disabled}
                    data-testid={props?.["data-testid"] || "dropdownButton"}
                >
                    {props.isApplied && (
                        <X
                            className="-ml-1 mr-2 pt-px pr-0.5"
                            aria-hidden="true"
                            onClick={props.onClear}
                            data-testid="xIcon"
                            size={20}
                            weight="bold"
                        />
                    )}
                    {buttonLabelElement}
                    {buttonIconElement}
                </PopoverButton>
            </div>

            <Transition
                as={"div"}
                ref={dropDownMenuRef}
                show={isShowing}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                className="relative z-40"
            >
                <PopoverPanel
                    static
                    className={classNames(
                        !clearDefaultMenuClassName
                            ? `absolute mt-1 w-full md:w-80 max-w-[94vw] border border-stroke rounded bg-white modal-shadow focus:outline-none normal-case z-40 ${
                                  isChangePadding ? "p-4" : "p-2"
                              }`
                            : "",
                        props.menuClassName ? props.menuClassName : "",
                        menuPosClassName
                    )}
                >
                    {props.headerTitle && (
                        <h3 className="text-header font-medium mb-4">{props.headerTitle}</h3>
                    )}
                    <div>{props.children}</div>
                    {showFooter &&
                        (!renderCustomFooter ? (
                            <div
                                className={classNames(
                                    footerClassName
                                        ? footerClassName
                                        : "pt-2 mt-2 flex items-center justify-end gap-2"
                                )}
                            >
                                <button
                                    type="button"
                                    className={"text-normal py-2 px-4 text-[15px] text-[#656260]"}
                                    onClick={props.onClear}
                                >
                                    Clear
                                </button>
                                <button
                                    type="button"
                                    className={"btn-primary"}
                                    onClick={handleApply}
                                    disabled={props.disabledApply}
                                >
                                    {buttonApplyLabel}
                                </button>
                            </div>
                        ) : (
                            renderCustomFooter
                        ))}
                </PopoverPanel>
            </Transition>
            {isShowing && !hideBackgroundOverlay && <div className={"fixed inset-0 z-[39]"} />}
        </Popover>
    );
}
