import React, { PropsWithChildren } from "react";
import { UserProfile } from "api/user/profile";
import CopyButtonQuote from "components/common/Copybutton/CopyButtonQuote";
import Link from "next/link";
import { OnboardingWidgetCompanyInfoCopied } from "analytics/onboarding/GettingStartedAnalytics";
import { Card } from "components/common/v2/Card";
import { FORA_PHONE_NUMBER, IATA } from "constants/index";

export const BookingInformation = ({
    userProfile,
    className
}: {
    userProfile: UserProfile;
    className?: string;
}) => {
    const userAnalyticsBlock = {
        email: userProfile.email,
        first_name: userProfile.firstName,
        last_name: userProfile.lastName
    };

    const BookingInformationItem = ({
        label,
        copyContent,
        contentType,
        children
    }: PropsWithChildren<{
        label: string;
        copyContent?: string;
        contentType?: string;
    }>) => {
        return (
            <div key={label}>
                <span className="flex justify-start items-center pb-1">
                    <h5 className="text-secondaryDark font-medium tracking-wide text-small">
                        {label}
                    </h5>
                    {copyContent && (
                        <CopyButtonQuote
                            contentToCopy={copyContent}
                            analyticsData={() =>
                                OnboardingWidgetCompanyInfoCopied({
                                    ...userAnalyticsBlock,
                                    contentType: contentType || ""
                                })
                            }
                        />
                    )}
                </span>
                <div className={`text-main whitespace-pre-line ${copyContent ? "-mt-2" : ""}`}>
                    {children}
                </div>
            </div>
        );
    };

    return (
        <Card className={className}>
            <div className="grid gap-[9px]">
                <h2 className="text-header font-medium">Booking information</h2>
                <div className="flex flex-col md:flex-row gap-8 md:gap-0">
                    <div className="flex flex-col gap-y-6 md:gap-y-4 grow">
                        <BookingInformationItem label="IATA#" copyContent={IATA} contentType="IATA">
                            33520476
                        </BookingInformationItem>
                        <BookingInformationItem label="BOOK IN PORTAL">
                            Travel partners, rates, and commission details all in the{" "}
                            <Link href="/partners" className="text-link">
                                Partners
                            </Link>{" "}
                            section
                        </BookingInformationItem>
                        <BookingInformationItem label="BOOK OUTSIDE PORTAL">
                            <p>You can book directly or through trusted 3rd parties.</p>
                            Visit{" "}
                            <Link href="/partners" className="text-link">
                                Partners
                            </Link>{" "}
                            to see instructions for hotels, cruises, tours, and more.
                            <div className="bg-blueLight rounded-md px-6 py-4 mt-6">
                                Remember when booking outside Portal:
                                <ol className="list-decimal ml-6">
                                    <li>
                                        Confirm the rate is commissionable with partner prior to
                                        booking
                                    </li>
                                    <li>Include IATA# on booking</li>
                                    <li>
                                        Forward confirmation to{" "}
                                        <a
                                            className="text-link"
                                            href="mailto:commissions@fora.travel"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            commissions@fora.travel
                                        </a>{" "}
                                        to get paid
                                    </li>
                                </ol>
                            </div>
                        </BookingInformationItem>
                    </div>
                    <div className="min-w-8 grow hidden md:block"></div>
                    <div className="flex flex-col gap-y-6 md:gap-y-8 min-w-[218px]">
                        <BookingInformationItem
                            label="MAILING ADDRESS"
                            copyContent="Fora Travel, Inc.<br>
                                228 Park Ave South #53272<br>
                                New York, NY 10003-1502"
                            contentType="Mailing Address"
                        >
                            <p>Fora Travel, Inc.</p>
                            <p>228 Park Ave South #53272</p>
                            New York, NY 10003-1502
                        </BookingInformationItem>

                        <BookingInformationItem
                            label="PHONE NUMBER"
                            copyContent="+1 844-409-3672"
                            contentType="Phone Number"
                        >
                            {FORA_PHONE_NUMBER}
                        </BookingInformationItem>
                        <BookingInformationItem label="MORE...">
                            <ul>
                                <li>
                                    Ask{" "}
                                    <Link href="/sidekick" className="text-link">
                                        Sidekick
                                    </Link>
                                </li>
                                <li>
                                    Visit{" "}
                                    <Link href="/help" className="text-link">
                                        Help Center
                                    </Link>{" "}
                                    for Support
                                </li>
                                <li>
                                    Read the{" "}
                                    <Link href="/community-guidelines" className="text-link">
                                        Community Guidelines
                                    </Link>
                                </li>
                            </ul>
                        </BookingInformationItem>
                    </div>
                </div>
            </div>
        </Card>
    );
};
